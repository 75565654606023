import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PropTypes from "prop-types";
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import "moment/locale/pt-br";

moment.locale('pt-br');

function CurrencyFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onFocus={e => e.target.select()}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.floatValue,
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            isNumericString
        />
    );
}

CurrencyFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function FloatFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onFocus={e => e.target.select()}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.floatValue,
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            isNumericString
        />
    );
}

FloatFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function IntegerFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            decimalScale={0}
            fixedDecimalScale
            isNumericString
        />
    );
}

IntegerFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function CPFFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            format={"###.###.###-##"}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    },
                });
            }}
            allowEmptyFormatting
        />
    )
}

CPFFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function CNPJFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            format={"##.###.###/####-##"}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    },
                });
            }}
            allowEmptyFormatting
        />
    )
}

CNPJFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function CEPFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            format={"##.###-###"}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    },
                });
            }}
            allowEmptyFormatting
        />
    )
}

CEPFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function MatriculaFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            inputRef={inputRef}
            format={"##.###"}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    },
                });
            }}
            allowEmptyFormatting
        />
    )
}

MatriculaFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function DateField(props) {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                {...props}
                autoOk={true}
                variant="inline"
                format="DD/MM/YYYY"
                label={props.label}
                onChange={e => props.onChange(e, props.name)}
                fullWidth={props.fullWidth}
                size={props.size}
                name={props.name}
                KeyboardButtonProps={{
                    'aria-label': 'Selecione a data',
                }}
                disabled={props.disabled}
            />
        </MuiPickersUtilsProvider>
    )
}

function DateTimeField(props) {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
                {...props}
                autoOk={true}
                allowKeyboardControl
                variant="inline"
                format="DD/MM/YYYY HH:mm:ss"
                label={props.label}
                onChange={e => props.onChange(e, props.name)}
                fullWidth={props.fullWidth}
                size={props.size}
                name={props.name}
                KeyboardButtonProps={{
                    'aria-label': 'Selecione a data',
                }}
                disabled={props.disabled}
                views={["date", "hours", "minutes", "seconds"]}
            />
        </MuiPickersUtilsProvider>
    )
}

function FloatField(props) {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: FloatFormat
            }}
        />
    )
}

function IntegerField(props) {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: IntegerFormat
            }}
        />
    )
}

function CurrencyField(props) {
    return (
        <TextField
            {...props}
            InputProps={{
                readOnly: props.readOnly,
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                inputComponent: CurrencyFormat
            }}
        />
    )
}

function MaskedField(props) {
    let format = {};

    switch (props.type) {
        case 'CPF': format = { inputComponent: CPFFormat }; break;
        case 'CNPJ': format = { inputComponent: CNPJFormat }; break;
        case 'CEP': format = { inputComponent: CEPFormat }; break;
        case 'Matricula': format = { inputComponent: MatriculaFormat }; break;
        default: format = { inputComponent: CPFFormat }; break;
    }

    return (
        <TextField
            {...props}
            InputProps={format}
        />
    )
}


export { DateField, DateTimeField, FloatField, IntegerField, CurrencyField, MaskedField };