import React, { useState, useEffect, useCallback } from 'react';
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Card,
    CardContent,
    CardActions,
    Button,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    Checkbox
} from '@material-ui/core';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { Doughnut, Pie, Bar, Line } from 'react-chartjs-2';

import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import MonetizationIcon from '@material-ui/icons/MonetizationOnOutlined';

import PageTitle from '../../components/PageTitle';
import FilterContainer from '../../components/FilterContainer';
import Loading from '../../components/Loading';
import { DateTimeField } from "../../components/MaskedFields";

import { ListItemSummary } from './styles';

import moment from 'moment';

import DashboardService from '../../services/Dashboard';
import Stores from '../../services/Stores';
import Marketplaces from '../../services/Marketplaces';

moment.locale('pt-br');

const exportToExcel = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(fileData, fileName);
};

const Dashboard = () => {

        const [data, setData] = useState({
            isLoading: true,
            summary: null,
            ordersByStatus: null,
            ordersByMarketplace: null,
            ordersByMarketplaceColors: null,
            ordersByMarketplaceHover: null,
            ordersByState: null,
            ordersByDay: null,
            topProducts: null
        });
    
        const [marketplaces, setMarketplaces] = useState([]);
        const [filters, setFilters] = useState({
            qs: "&dateFrom=" + moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss') + "&dateTo=" + moment().format('YYYY-MM-DDTHH:mm:ss'),
            dateFrom: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
            dateTo: moment().format('YYYY-MM-DDTHH:mm:ss'),
            marketplaces: []
        })
    
        const dynamicColors = (aux, total, hover) => {
            var r = aux * 100 / total;
            var g = aux * 255 / total;
            var b = 200 + aux * 155 / total;
            return "rgba(" + r + "," + g + "," + b + ", " + hover + ")";
        };
    
        const statusColors = [
            {
                status: 'Novo',
                color: "rgba(200,200,200,1)",
                hover: "rgba(200,200,200,0.8)"
            },
            {
                status: 'Aprovado',
                color: "rgba(251,202,001,1)",
                hover: "rgba(251,202,001,0.8)",
            },
            {
                status: 'Faturado',
                color: "rgba(000,100,255,1)",
                hover: "rgba(000,100,255,0.8)"
            },
            {
                status: 'Enviado',
                color: "rgba(000,050,200,1)",
                hover: "rgba(000,050,200,0.8)"
            },
            {
                status: 'Entregue',
                color: "rgba(042,117,046,1)",
                hover: "rgba(042,117,046,0.8)"
            },
            {
                status: 'Cancelado',
                color: "rgba(255,000,076,1)",
                hover: "rgba(255,000,076,0.8)"
            },
        ]
    
        const OrdersDashboard = useCallback((filters) => {
            setData({
                isLoading: true
            })
    
            DashboardService.orders(filters).then(result => {
                let newData = {
                    isLoading: false,
                    summary: result.summary,
                    ordersByStatus: result.ordersByStatus,
                    ordersByMarketplace: result.ordersByMarketplace,
                    ordersByMarketplaceColors: [],
                    ordersByMarketplaceHover: [],
                    ordersByState: result.ordersByState,
                    ordersByDay: result.ordersByDay,
                    topProducts: result.topProducts
                }
    
                for (let index = 0; index < result.ordersByMarketplace.length; index++) {
                    newData.ordersByMarketplaceColors.push(dynamicColors(index, result.ordersByMarketplace.length, 0.6));
                    newData.ordersByMarketplaceHover.push(dynamicColors(index, result.ordersByMarketplace.length, 0.5));
                }
    
                setData(newData);
    
            }).catch(error => {
                console.log(error);
            });
        }, [])
    
        const RetrieveStore = useCallback(() => {
            Stores.retrieve().then(async result => {
                let marketplaceSettings = result.marketplaceSettings.filter(x => x.active === true);
                let marketplaces = [];
    
                for (let index = 0; index < marketplaceSettings.length; index++) {
                    const marketplace = marketplaceSettings[index];
    
                    if (!marketplaces.find(x => x.id === marketplace.marketplace)) {
                        let marketplaceInfo = await Marketplaces.retrieveByMarketplace(marketplace.marketplace);
                        marketplaces.push({
                            id: marketplace.marketplace,
                            name: marketplaceInfo.name,
                            sellerId: String(marketplace.credentials.sellerId),
                            sellerName: marketplace.credentials.sellerName
                        });
                    }
                }
    
                setMarketplaces(marketplaces);
            }).catch(error => {
                console.log(error);
            });
        }, [])
    
        const handleChangeDate = (date, field) => {
            if (date) {
                let updateFilters = { ...filters };
                updateFilters[field] = date.format('YYYY-MM-DDTHH:mm:ss');
                setFilters(updateFilters);
            }
        };
    
        const handleChangeMarketplaces = (event) => {
            let updateFilters = { ...filters };
            updateFilters.marketplaces = event.target.value;
            setFilters(updateFilters);
        };
    
        const handleApplyFilters = () => {
    
            const updateFilters = { ...filters };
            const updateMarketplaces = updateFilters.marketplaces;
    
            delete updateFilters.qs;
            delete updateFilters.marketplaces;
    
            updateFilters.qs = new URLSearchParams(updateFilters);
    
            for (let index = 0; index < updateMarketplaces.length; index++) {
                const marketplaceName = updateMarketplaces[index];
                updateFilters.qs.append('marketplaces', marketplaces.find(x => x.name === marketplaceName).id);
            }
    
            updateFilters.marketplaces = updateMarketplaces;
            updateFilters.qs = updateFilters.qs.toString();
    
            setFilters(updateFilters);
        }
    
        const handleDismissFilters = () => {
            setFilters({
                qs: "&dateFrom=" + moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss') + "&dateTo=" + moment().format('YYYY-MM-DDTHH:mm:ss'),
                dateFrom: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                dateTo: moment().format('YYYY-MM-DDTHH:mm:ss'),
                marketplaces: []
            });
        }
    
        const handleExportClick = () => {
    
            const orders = [];
    
            for (let index = 0; index < data.ordersByDay.length; index++) {
                const order = data.ordersByDay[index];
                orders.push({
                    "Dia": order.day,
                    "Pedidos": order.quantity,
                    "Ticket Médio": Number(order.averageTicket?.toFixed(2)),
                    "Total": Number(order.total?.toFixed(2))
                })
            }
    
            exportToExcel(orders, 'pedidos-por-dia-'+moment().format('DD-MM-YYYY-HH-mm-ss')+'.xlsx');
        };
    
        useEffect(() => {
            RetrieveStore();
            OrdersDashboard(filters.qs);
        }, [RetrieveStore, OrdersDashboard, filters.qs]);
    
        const { isLoading } = data;

    return (
        <>
            <PageTitle title="Dashboard" />
            {<FilterContainer
                title="Filtros"
                handleApplyFilters={handleApplyFilters}
                handleDismissFilters={handleDismissFilters}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <DateTimeField
                            name="dateFrom"
                            label="Data de"
                            value={filters.dateFrom}
                            onChange={handleChangeDate}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DateTimeField
                            name="dateTo"
                            label="Data até"
                            value={filters.dateTo}
                            onChange={handleChangeDate}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="marketplaces">Marketplaces</InputLabel>
                            <Select
                                id="marketplaces"
                                labelid="marketplaces"
                                multiple
                                value={filters.marketplaces}
                                onChange={handleChangeMarketplaces}
                                input={<Input />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 250
                                        },
                                    },
                                }}
                                size="small"
                                fullWidth
                            >
                                {marketplaces && marketplaces.map(item => (
                                    <MenuItem key={item.name} value={item.name}>
                                        <Checkbox checked={filters.marketplaces.indexOf(item.name) > -1} />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </FilterContainer>}
            {!isLoading ?
                <Grid container justify="center" spacing={2} >
                    <Grid key={0} item sm={12} md={6}>
                        <Card style={{ height: '100%' }} >
                            <CardContent style={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography align="center" gutterBottom>
                                    Status dos Pedidos
                                </Typography>
                                {data.ordersByStatus && (
                                    <Doughnut
                                        data={{
                                            labels: data.ordersByStatus.map(x => x._id),
                                            datasets: [{
                                                label: 'Status dos pedidos',
                                                data: data.ordersByStatus.map(x => x.quantity),
                                                backgroundColor: statusColors.filter(x => data.ordersByStatus.map(y => y._id).includes(x.status)).map(a => a.color),
                                                hoverBackgroundColor: statusColors.filter(x => data.ordersByStatus.map(y => y._id).includes(x.status)).map(a => a.hover),
                                                borderWidth: 0
                                            }]
                                        }}
                                        options={{
                                            legend: {
                                                position: 'bottom',
                                                labels: {
                                                    boxWidth: 12
                                                }
                                            }
                                        }}
                                    />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid key={1} item sm={12} md={6}>
                        <Card style={{ height: '100%' }} >
                            <CardContent style={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography align="center" gutterBottom>
                                    Visão geral
                                </Typography>
                                {data.summary && (
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <DescriptionIcon color="primary" style={{ fontSize: '40px', marginRight: '20px' }} />
                                            </ListItemIcon>
                                            <ListItemSummary
                                                primary={data.summary.quantity}
                                                secondary="pedidos"
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ReceiptIcon color="primary" style={{ fontSize: '40px', marginRight: '20px' }} />
                                            </ListItemIcon>
                                            <ListItemSummary
                                                primary={data.summary.averageTicket?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                secondary="ticket médio"
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <MonetizationIcon color="primary" style={{ fontSize: '40px', marginRight: '20px' }} />
                                            </ListItemIcon>
                                            <ListItemSummary
                                                primary={data.summary.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                secondary="vendas"
                                            />
                                        </ListItem>
                                    </List>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid key={2} item sm={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography align="center" gutterBottom>
                                    Pedidos por Marketplace (Quantidade)
                                </Typography>
                                {data.ordersByMarketplace && (
                                    <Pie
                                        data={{
                                            labels: data.ordersByMarketplace.map(x => x._id),
                                            datasets: [{
                                                label: 'Pedidos por Marketplace (Quantidade)',
                                                data: data.ordersByMarketplace.map(x => x.quantity),
                                                backgroundColor: data.ordersByMarketplaceColors,
                                                hoverBackgroundColor: data.ordersByMarketplaceHover,
                                                borderWidth: 0
                                            }]
                                        }}
                                        options={{
                                            legend: {
                                                position: 'bottom',
                                                labels: {
                                                    boxWidth: 12
                                                }
                                            }
                                        }}
                                    />
                                )}
                            </CardContent>
                            <CardActions>
                                <Button size="small">Ver pedidos</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid key={3} item sm={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography align="center" gutterBottom>
                                    Pedidos por Marketplace (Valor)
                                </Typography>
                                {data.ordersByMarketplace && (
                                    <Pie
                                        data={{
                                            labels: data.ordersByMarketplace.map(x => x._id),
                                            datasets: [{
                                                label: 'Pedidos por Marketplace (Valor)',
                                                data: data.ordersByMarketplace.map(x => x.total),
                                                backgroundColor: data.ordersByMarketplaceColors,
                                                hoverBackgroundColor: data.ordersByMarketplaceHover,
                                                borderWidth: 0
                                            }]
                                        }}
                                        options={{
                                            legend: {
                                                position: 'bottom',
                                                labels: {
                                                    boxWidth: 12
                                                }
                                            }
                                        }}
                                    />
                                )}
                            </CardContent>
                            <CardActions>
                                <Button size="small">Ver pedidos</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid key={4} item sm={12} lg={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }} >
                        <Card style={{ height: '48%', marginBottom: '10px' }}>
                            <CardContent style={{ height: '90%' }}>
                                <Typography align="center" gutterBottom>
                                    Pedidos por Estado
                                </Typography>
                                {data.ordersByState && (
                                    <Bar
                                        data={{
                                            labels: data.ordersByState.map(x => x._id),
                                            datasets: [{
                                                label: 'Pedidos',
                                                data: data.ordersByState.map(x => x.total),
                                                backgroundColor: 'rgba(50, 102, 222, 0.6)',
                                                borderWidth: 1
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            legend: {
                                                display: false
                                            }
                                        }}
                                    />
                                )}
                            </CardContent>
                        </Card>
                        <Card style={{ height: '48%' }}>
                            <CardContent style={{ height: '90%' }}>
                                <Typography align="center" gutterBottom>
                                    Pedidos por Dia
                                </Typography>
                                {data.ordersByDay && (
                                    <Line
                                        data={{
                                            labels: data.ordersByDay.map(x => x.day),
                                            datasets: [{
                                                label: 'Pedidos',
                                                data: data.ordersByDay.map(x => x.quantity),
                                                backgroundColor: 'rgba(50, 102, 222, 0.6)',
                                                borderWidth: 1
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            legend: {
                                                display: false
                                            }
                                        }}
                                    />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid key={6} item sm={12} lg={6} style={{ display: 'flex', flexDirection: 'column' }} >
                        <Card>
                            <CardContent>
                                <Typography align="center" gutterBottom>
                                    Pedidos por Dia
                                </Typography>
                                {data.ordersByDay && (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Dia</TableCell>
                                                <TableCell align="right">Pedidos</TableCell>
                                                <TableCell align="right">Ticket Médio</TableCell>
                                                <TableCell align="right">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.ordersByDay.map((day, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{day.day.toString()}</TableCell>
                                                    <TableCell align="right">{day.quantity}</TableCell>
                                                    <TableCell align="right">{day.averageTicket?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                    <TableCell align="right">{day.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                            </CardContent>
                            <CardActions dir='rtl'>
                                <Button color="primary" size="small" onClick={handleExportClick}>Exportar para Excel</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid key={7} item sm={12} md={12}>
                        <Card>
                            <CardContent>
                                <Typography align="center" gutterBottom>
                                    Produtos Mais Vendidos
                                </Typography>
                                {data.topProducts && (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Imagem</TableCell>
                                                <TableCell align="left">Produto</TableCell>
                                                <TableCell align="center">Quantidade</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.topProducts.map((product, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">
                                                        <img src={product.thumbnail} alt='Miniatura' />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Link href={"/products/" + product._id} target="_blank">
                                                            {product.title}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell align="center">{product.quantity}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                :
                <Loading open={isLoading} />}
        </>
    )
}

export default Dashboard;